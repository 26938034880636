import styled from 'styled-components';

export const StyledButton = styled.button`
  background: none;
  color: #858280;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 32px;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 10px;
  
  .selected {
    color: #212020;
    background: #FFF;
  }
`;