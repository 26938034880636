import React, { useState } from 'react';

// styles
import '../styles/index.css';
import '/node_modules/primeflex/primeflex.css';

// components
import MainLayout from '../hoc/MainLayout';
import Selection from '../components/shared/Selection';
import { pricingFaq, musicFaq, toolsFaq } from '../common/faq';
import Dropdown from '../components/shared/Dropdown';
import { PrimaryLinkButton } from '../components/shared/StyledButtons/styled';
import { loadCrispChat } from '../common/utils';
import Seo from '../components/shared/Seo';

const FaqPage = () => {
    loadCrispChat();
    const options = ['Premium 🚀', 'Tools 📝 ', 'Music 🎵'];
    const [selected, setSelected] = useState(options[0]);

    const getFaq = () => {
        if (selected === 'Premium 🚀') {
            return pricingFaq;
        } else if (selected === 'Tools 📝 ') {
            return toolsFaq;
        } else if (selected === 'Music 🎵') {
            return musicFaq;
        } else return null;
    };

    return (
        <MainLayout>
            <Seo title='Lofi.co - Craft your focus environment' keywords={['music', 'focus', 'relax', 'work']} lang='en' />
            <main>
                <div className='flex flex-column align-items-center mb-8'>
                    <h2 className='text-center text-4xl md:text-5xl font-bold mb-6'>
                        Frequently Asked Questions
                    </h2>
                    <div className='mb-6'>
                        <Selection options={options} selected={selected} setSelected={setSelected} />
                    </div>
                    <div className='mb-8'>
                        {getFaq().map((faq, index) => {
                            return (
                                <Dropdown key={index} faq={faq} />
                            );
                        })}
                    </div>
                    <h3 className='text-3xl md:text-4xl font-bold mb-6'>
                        Didn&apos;t find your answer?
                    </h3>
                    <div className="font-helvetica">
                        <PrimaryLinkButton to='/contact'>Contact us</PrimaryLinkButton>
                    </div>
                </div>
            </main>
        </MainLayout>
    );
};

export default FaqPage;