export const pricingFaq = [
    {
        question: 'How does the 7 day money-back guarantee work?',
        answer: 'With the 7-day money back guarantee you can contact us within 7 days after you subscribe to premium and request a refund.'
    },
    {
        question: 'Can I cancel the membership?',
        answer: 'Sure, you can cancel the membership at any time from your profile settings.'
    },
    {
        question: 'How do you manage credit card data?',
        answer: 'We don\'t store credit card data on our servers. All payment processing is handled by Stripe, a certified Level 1 PCI Service Provider (the most stringent level of certification available). When credit card data is submitted, it is sent directly to Stripe via JavaScript over a secure SSL connection. The payment data never touches our servers.'
    },
    {
        question: 'How often do you add new scenes?',
        answer: 'We add at least 2 new scenes a month, including all their variations such as rainy/sunny and day/night.'
    },
    {
        question: 'How do you support mental health foundations?',
        answer: 'We donate 1% of our monthly revenue to a specific organization of our choice. Since launch, we have donated every month to the Brain & Behavior Research Foundation.'
    },
];

export const toolsFaq = [
    {
        question: 'How does the advanced timer work?',
        answer: 'The advanced timer is a customizable pomodoro timer with time tracking. Enable \'task tracking\' in the settings to show how much time you spend on each task, and create a session to also track your work and break time.'
    },
    {
        question: 'How does a session work?',
        answer: 'You can start a session from the ‘+’ button in the timer if you want to record the total time, break time and focus time of a session. Every completed session is also saved in the History, where you can check back on all your past work.'
    },
    {
        question: 'Can I use the timer without a session?',
        answer: 'Sure, but note that you won’t be able to record the total time, break time and focus time and there won’t be any data saved in the insights.'
    },
    {
        question: 'Can I use the timer without tasks?',
        answer: 'Every feature of the timer works without tasks, you can also activate the Focus Mode to completely hide the tasks section.'
    },
    {
        question: 'What’s a session template?',
        answer: 'As soon as you complete a session, you can save it as a template in order to quickly start a new one with the same settings and tasks.'
    },
];

export const musicFaq = [
    {
        question: 'How often do you update the music rotation?',
        answer: 'The music rotation is updated every 48-72 hours on the native lofi.co player and every 24 hours on the Spotify player.'
    },
    {
        question: 'How can I support the producers and labels?',
        answer: 'The best way to support the producers is to use the Spotify embedded player. If you prefer our native player, please check out the artists/labels pages by clicking on their logo in the bottom left of the screen.'
    },
    {
        question: 'Can I use the music in my YouTube videos/streams?',
        answer: 'We do not guarantee all the music played on lofi.co is copyright free, therefore it would be best not to use it in videos/streams.'
    },
    {
        question: 'Why does the Spotify player only play for the first 20 seconds?',
        answer: 'In order to use the Spotify player without any limits, you have to login to your Spotify account by following these easy steps:',
        more: [
            'Step 1: Click login, a Spotify login page will open',
            'Step 2: Login to your Spotify Account',
            'Step 3: Go back to the lofi.co tab and refresh'
        ]
    },
];