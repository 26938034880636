import React from 'react';
import { StyledButton } from './styled';

export default function Selection({ options, selected, setSelected }) {

    return (
        <div style={{
            background: '#EDEAE7',
            padding: '8px',
            borderRadius: '32px',
            width: 'fit-content',
            marginBottom: '2.5rem'
        }}>
            {options.map(option => {
                return (
                    <StyledButton
                        className='w-6rem md:w-10rem text-xs md:text-base font-helvetica font-bold'
                        onClick={() => setSelected(option)}
                        key={option}
                        style={ selected === option ?
                            {
                                color: '#212020',
                                background: '#FFF',
                                boxShadow: '0 2px 3px rgba(0, 0, 0, 0.15)'
                            } : null}>
                        {option}
                    </StyledButton>
                );
            })}
        </div>
    );
}