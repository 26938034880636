import React, { useState } from 'react';
import MinusIcon from '../../../assets/minus-icon.svg';
import PlusIcon from '../../../assets/plus-icon.svg';
import { UnstyledButton } from '../UnstyledLink/styled';

export default function Dropdown({ faq }) {
    const { question, answer, more } = faq;
    const [isOpen, setIsOpen] = useState(false);
    console.log(more);
    return (
        <div style={{ width: '80vw' }} className="font-helvetica">
            <div className='flex justify-content-between align-items-center mb-3'>
                <p className='font-bold text-lg'>{question}</p>
                <UnstyledButton onClick={() => setIsOpen(!isOpen)}>
                    {isOpen ? <MinusIcon /> : <PlusIcon />}
                </UnstyledButton>
            </div>
            {isOpen && (
                <>
                    <p style={{ color: '#767676' }}>{answer}</p>
                    {more != null && more.map((step, index) => {
                        return <div style={{ color: '#767676' }} key={index} >{step}</div>;
                    })}
                </>
            )}
            <hr className='my-5' style={{ color: '#B1B1B1' }} />
        </div>
    );
}